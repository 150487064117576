import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
    description?: string
    title?: string
    url: string
}

const defaultTitle = 'MW Training | First Aid Courses'
const defaultDescription =
    'M.W Training delivers up to date and quality training in first aid to ensure your company or school meets its health and safety requirements to your employees. Based in Norfolk, we are flexible can come to you and deliver a training package or organise a training venue elsewhere.'
const MetaData = ({ title, description, url }: Props) => {
    const splitUrl = url?.split('/') ?? []
    const imagePath = !!splitUrl && `${splitUrl[0]}${splitUrl[1]}${splitUrl[2]}/logo.png`
    return (
        <Helmet>
            <title>{title ?? defaultTitle}</title>
            <meta name="description" content={description ?? defaultDescription} />
            <meta property="og:locale" content="en_GB"></meta>
            <meta property="og:site_name" content="MW Training"></meta>
            {splitUrl && <meta property="og:url" content={url}></meta>}
            {imagePath && <meta property="og:image" content={imagePath}></meta>}
            <meta property="og:image:width" content="447"></meta>
            <meta property="og:image:height" content="447"></meta>
            {splitUrl && <link rel="canonical" href={url}></link>}
        </Helmet>
    )
}

export default MetaData
